import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import { admin, staff, stockman, superadmin, admin_assistant, voter } from 'app/utils/constant';

const UserManagement = Loadable(lazy(() => import('./users/UserManagement')));
const ElectionList = Loadable(lazy(() => import('./election/ElectionList')));
const Report = Loadable(lazy(() => import('./report/Report')));
const Teller = Loadable(lazy(() => import('./teller/Teller')));
const Ticket = Loadable(lazy(() => import('./ticket/Ticket')));
const Fight = Loadable(lazy(() => import('./fight/Fight')));
const BettingPage = Loadable(lazy(() => import('./betting/Betting')));
const ElectionResults = Loadable(lazy(() => import('../dashboard/ElectionResults')));
const NotFound = Loadable(lazy(() => import('./NotFound')));
const Forbidden = Loadable(lazy(() => import('./Forbidden')));
const systemRoutes = [
  {
    path: '/dashboard/default',
    element: <ElectionResults />,
    auth: [superadmin, admin]
  },

  { path: '/user-management', element: <UserManagement />, auth: [superadmin] },

  { path: '/events', element: <ElectionList />, auth: [superadmin] },
  { path: '/sales-summary', element: <Report />, auth: [superadmin, admin] },
  { path: '/teller-summary', element: <Teller />, auth: [superadmin, staff] },
  { path: '/ticket-logs', element: <Ticket />, auth: [superadmin, staff] },
  { path: '/fights', element: <Fight />, auth: [superadmin, admin, staff] },
  { path: '/betting', element: <BettingPage />, auth: [superadmin, admin, staff] },

  {
    path: '/system/not-found',
    element: <NotFound />,
    auth: [voter, superadmin, admin, staff]
  },
  {
    path: '/system/forbidden',
    element: <Forbidden />,
    auth: [voter, superadmin, admin, staff]
  }
];

export default systemRoutes;
