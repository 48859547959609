import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import axios from 'axios.js';
import { MatxLoading } from 'app/components';
import { red } from '@mui/material/colors';

const BaseUrl = process.env.REACT_APP_API_URL_ROOT;

const reducer = (state, action) => {
  switch (action.type) {
    case 'ALLOWED_VOTES': {
      return {
        ...state,
        AllowedVotes: action.payload
      };
    }
    case 'CASTED_VOTES': {
      return {
        ...state,
        Votes: action.payload
      };
    }
    case 'LOAD_EVENTS': {
      return {
        ...state,
        Events: action.payload
      };
    }
    default: {
      return { ...state };
    }
  }
};

const SystemContext = createContext({
  Votes: 0,
  AllowedVotes: 0,
  ManagerDetails: [],
  Events: [],
  MESSAGE: '',
  STATUS: 400,
  Get: () => Promise.resolve(),
  Post: () => Promise.resolve(),
  Put: () => Promise.resolve(),
  Delete: () => Promise.resolve(),
  LoadEvents: () => Promise.resolve(),
  LoadFights: () => Promise.resolve(),
  LoadTickets: () => Promise.resolve(),
  SetAllowedVotes: () => Promise.resolve(),
  AddMyVotes: () => Promise.resolve()
});

export const SystemProvider = ({ settings, children }) => {
  const [state, dispatch] = useReducer(reducer, []);

  const Get = async (api, params) => {
    try {
      const res = await axios.get(`${BaseUrl}/${api}`, { params });
      return res.data;
    } catch (e) {
      console.error(e);
    }
  };
  const LoadEvents = async (payload) => {
    try {
      const res = await axios.get(BaseUrl + '/event/list', payload);
      await dispatch({
        type: 'LOAD_EVENTS',
        payload: res.data
      });
    } catch (e) {
      console.error(e);
    }
  };
  const Post = async (api, payload) => {
    try {
      const res = await axios.post(BaseUrl + '/' + api, payload);
      return res.data;
    } catch (e) {
      return e;
    }
  };

  const Put = async (api, payload) => {
    try {
      const res = await axios.put(BaseUrl + '/' + api, payload);
      return res.data;
    } catch (e) {
      console.error(e);
    }
  };
  const Delete = async (api, payload) => {
    try {
      const res = await axios.delete(BaseUrl + '/' + api);
      return res.data;
    } catch (e) {
      console.error(e);
    }
  };
  const SetAllowedVotes = async (payload) => {
    await dispatch({
      type: 'ALLOWED_VOTES',
      payload: payload
    });
  };

  const AddMyVotes = async (payload) => {
    await dispatch({
      type: 'CASTED_VOTES',
      payload: payload
    });
  };

  return (
    <SystemContext.Provider
      value={{
        Get,
        Post,
        Put,
        Delete,
        AddMyVotes,
        SetAllowedVotes,
        LoadEvents,
        Events: state.Events
      }}
    >
      {children}
    </SystemContext.Provider>
  );
};
export default SystemContext;
