import { superadmin, admin, staff } from 'app/utils/constant';
export const navigations = [
  {
    name: 'Event',
    auth: [superadmin],
    icon: 'poll',
    path: '/events'
  },
  {
    name: 'Sales Summary',
    auth: [superadmin],
    icon: 'show_chart',
    path: '/sales-summary'
  },
  {
    name: 'Tellers',
    auth: [superadmin, staff],
    icon: 'account_box',
    path: '/teller-summary'
  },
  {
    name: 'Ticket Logs',
    auth: [superadmin, staff],
    icon: 'description',
    path: '/ticket-logs'
  },
  {
    name: 'Fights',
    auth: [superadmin, admin],
    icon: 'thumbs_up_down',
    path: '/fights'
  },
  {
    name: 'Betting',
    auth: [superadmin, staff],
    icon: 'recent_actors',
    path: '/betting'
  },

  {
    name: 'User Management',
    auth: [superadmin],

    icon: 'group',
    path: '/user-management'
  }
];
